<template>
    <div>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                    Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
        </v-sheet>
      <v-sheet height="600">
        <v-calendar
            ref="calendar"
            class="text-center"
            v-model="value"
            color="primary"
            :events="items"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @change="getAcademicCalendarEvents"
        >
        </v-calendar>
        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
          <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
          >
            <v-toolbar
                :color="selectedEvent.color"
                dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.des"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  text
                  class="btn-secondary"
                  @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </div>
</template>

<script>
import {CalenderMixin} from "@/mixins/CalenderMixin";

export default {
  name: "EventComponents",
  mixins: [CalenderMixin],
  data() {
      return {
          selectedEvent: {},
          selectedElement: null,
          selectedOpen: false,
          more: true,
          focus: '',
          type: 'month',
          mode: 'column',
          types: ['month', 'week', 'day', '4day'],
          typeToLabel: {
              month: 'Month',
              week: 'Week',
              day: 'Day',
              '4day': '4 Days',
          },
          value: '',
          weekday: [0, 1, 2, 3, 4, 5, 6],
          selectedPopupEvent: {},
      }
  },
  methods: {
      getEventColor (event) {
        return event.color
      },

      prev() {
          this.$refs.calendar.prev();
      },
      next() {
          this.$refs.calendar.next();
      },

      setToday() {
          this.focus = ''
      },
      viewDay ({ date }) {
        this.value = date
        this.type = 'day'
      },
      showEvent({nativeEvent, event}) {
          const open = () => {
              this.selectedPopupEvent = event
              this.selectedElement = nativeEvent.target
              setTimeout(() => {
                  this.selectedOpen = true
              }, 10)
          }

          if (this.selectedOpen) {
              this.selectedOpen = false
              setTimeout(open, 10)
          } else {
              open()
          }

          nativeEvent.stopPropagation()
      },
  },
  computed: {
      items() {
          const items = [];
          this.events.forEach((item) => {
              items.push({
                  id: item.id,
                  name: item.title,
                  start: new Date(item.start_date),
                  end: new Date(item.end_date),
                  type: item.type,
                  color: item.color,
                  timed: item.start_from || item.end_to,
              })
          })
          return items;
      },
      currentUser() {
          return this.$store.getters.currentUser;
      },
  },
}
</script>
