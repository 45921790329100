<template>
  <div id="profile-info">
    <!--        <v-alert-->

    <!--                dismissible-->
    <!--                border="left"-->
    <!--                type="info"-->
    <!--        >-->
    <!--            Notice Board message ?-->
    <!--        </v-alert>-->


    <div class="row ">
<!--      <pre>{{organisational_setting}}</pre>-->
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 " v-if="organisational_setting.enable_calendar">
        <v-card flat rounded>
          <v-card-text>
            <event-components></event-components>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
          <library-timeline></library-timeline>
      </div>

<!--      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12" v-if="organisational_setting.event_date">
        <v-card flat class="text-center">
          <v-card-title class="justify-center">
            News and notice
          </v-card-title>
          <v-card-text>

            <div class="card-body pt-2">
              &lt;!&ndash;begin::Item&ndash;&gt;
              <div v-for="(item ,i) in news" :key="i" class="mb-2 d-flex align-items-center">
                &lt;!&ndash;begin::Bullet&ndash;&gt;
                <span class="bullet bullet-bar bg-success align-self-stretch"></span>
                &lt;!&ndash;end::Bullet&ndash;&gt;
                &lt;!&ndash;begin::Checkbox&ndash;&gt;

                &lt;!&ndash;end::Checkbox&ndash;&gt;
                &lt;!&ndash;begin::Text&ndash;&gt;
                <div class="d-flex flex-column flex-grow-1">
                  <a href="#"
                     class=" text-left ml-5 text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 ">{{ item.title }}</a>
                  <span class="text-left ml-5 text-muted font-weight-bold">{{ item.pub_date }}</span>
                </div>
                &lt;!&ndash;end::Text&ndash;&gt;

              </div>
              &lt;!&ndash;end:Item&ndash;&gt;
              &lt;!&ndash;begin::Item&ndash;&gt;


            </div>
          </v-card-text>
        </v-card>
      </div>-->


    </div>
  </div>
</template>

<script>
import BlogService from "@/core/services/blog/BlogService";
import EventComponents from "@/view/component/EventComponents";
import UserService from "@/core/services/user/UserService";
import LibraryTimeline from "./LibraryTimeline.vue";

const userService = new UserService();
const blogService = new BlogService();
export default {
  name: "Student-Dashboard",
  props: ['organisational_setting'],
  components: {
    EventComponents,LibraryTimeline
  },
  watch: {},
  data() {
    return {

      user: null,
      studentSetting: null,
      courses: [],
      tab: null,
      subjects: [
        'Math', 'Web-I', 'Web-II', 'Account',
      ],
      events: [],
      news: [],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  // mounted() {
  //   this.getNews();
  // },
  methods: {

    getNews() {
      blogService
          .paginate({
            type: 'news'
          })
          .then(response => {
            this.news = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    checkUserIncludes(type, role) {
      if (type && type.length > 0 && type.includes(role))
        return true;
      return false;
    },

  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
}
</script>

<style scoped>
#profile-info .v-application--wrap {
  min-height: 0 !important;
}
</style>
